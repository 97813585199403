<template>
  <div class="list-info" id="watermark">
    <top-bar :title="'预产期'" :left="true"></top-bar>
    <div class="cont">
      <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList"
                  v-if="dataList.length > 0" offset="10">
          <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{ totalCount }}</span>&nbsp;条数据</div>
          <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.userId)">
            <div class="title">
              <div class="title-left">
                {{ "预产期: " + item.dueDate }} <span
                  style="color:#4D97EE;overflow:hidden ;text-overflow:ellipsis;white-space:nowrap;">({{ item.typeStr }})</span>
              </div>
              <div class="title-right">
                <a :href="'tel:'+item.mobile" @click.stop=""><img src="@/assets/img/phone.png" alt="" class="tel"/></a>
              </div>
            </div>
            <div class="content">
              <img :src="require('@/assets/img/headImgWoman.png')" class="content-img">
              <div class="content-text">
                <div class="top-text ">{{ item.name }}
                  <span class="grayness-small-character">/女</span>
                  <span v-if="item.age" class="grayness-small-character">/{{ item.age }}</span>
                </div>
                <div v-if="item.mobile" class="bottom-text ">手机号: {{ item.mobile }}</div>
                <div class="bottom-text ">证书编号: {{ item.certNumber ? item.certNumber : '无' }}</div>
              </div>
              <van-button type="info" size="small" v-if="item.noRemind != 1"  class="content-icon"  round @click.stop="remindHandle(item.id)">处理</van-button>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {formatterDate} from '@/utils/utils'
import {newDate} from '@/utils/utils'
import {mapMutations} from 'vuex'

export default {
  components: {
    topBar
  },
  data() {
    return {
      loading: false,
      finished: false,
      pullLoading: false,
      page: 0,
      limit: 10,
      selectDate: {
        orgId: "",
        id:''
      },
      totalCount: 0,
      dataList: []
    };
  },
  methods: {
    ...mapMutations,
    getInfo(userId) {
      if(userId){
        this.$router.push({path: '/userRes-info', query: {userId: userId}})
      }else{
        this.$toast.fail("没有居民的详细信息")
      }
    },
    getDataList() {
      this.$toast.loading({duration: 0, message: '加载中...', forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/pregnancy/list/dueDate'),
        method: 'post',
        params: this.$http.adornParams({
        })
      }).then(({data}) => {
        this.pullLoading = false
        if (data.code == 0) {
          this.totalCount = data.list.length
          this.dataList = this.dataList.concat(data.list)
          this.finished = true
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    /**
     * 页面相关事件处理函数--用户下拉动作
     */
    onPullDownRefresh() {
      this.finished = false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    remindHandle(id){
      // debugger
      let that=this
      this.$dialog.confirm({
        title: '是否确定取消提醒'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/wxapp/pregnancy/update'),
          method: 'post',
          data: this.$http.adornData({
            id:id,
            noRemind:1
          })
        }).then(({data}) => {
          if(data.code==0){
            this.$toast.success({
              message: "操作成功",
              duration: 1500,
              onOpened: () => {
                setTimeout(() => {
                  this.onPullDownRefresh()
                }, 1500)
              }
            })
          }else{
            this.$dialog.close(data.msg)
          }
        })
      })
    }
  },
  created() {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.getDataList()
  }
}
</script>
